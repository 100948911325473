<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet class="dense-inputs">
                        <v-row no-gutters>
                            <v-col class="d-flex flex-row" cols="12">
                                <v-autocomplete
                                    :items="filterItems.owner"
                                    :placeholder="$t('message.owner')"
                                    autocomplete="new-password"
                                    class="ml-0 filter-width-125"
                                    dense
                                    hide-details="auto"
                                    item-text="Owner.title"
                                    item-value="Owner.id"
                                    solo
                                    v-model="filterValues.owner"
                                    @change="filterCustomers()"
                                ></v-autocomplete>
                                <v-autocomplete
                                    :items="filterItems.country"
                                    :placeholder="$t('message.country')"
                                    autocomplete="new-password"
                                    class="ml-3 filter-width-175"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.title"
                                    item-value="Country.id"
                                    solo
                                    v-model="filterValues.country"
                                    @change="filterCustomers()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="d-flex flex-row align-center" cols="12">
                                <div class="font-sm pt-3">
<!--                                    <v-icon small color="success darken-1">fiber_manual_record</v-icon>{{ $t('message.newInStageSevenDays') }}-->
                                    <v-icon small color="success" class="ml-1 mr-3 mdi mdi-triangle"></v-icon>{{ $t('message.previousAppicBuyer') }}
                                    <v-icon style="color: #FF7A59 !important;" class="ml-5 mr-3 mdi mdi-hubspot"></v-icon>{{ $t('message.hubSpotLink') }}
                                </div>
                                <span class="mt-3 ml-5 font-sm">* New buyer in last 30 days</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                            <v-col class="pl-1 cols-20-pct">
                                <v-card class="grey-card" tile outlined>
                                    <template v-if="leads.loading">
                                        <v-progress-circular
                                            color="white"
                                            indeterminate
                                            size="30"
                                            width="4"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="conversion-widget text--black">
                                            {{ $t('message.leads') + ' - ' + leads.total.toString() }}
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on: menu }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                class="float-right"
                                                                icon
                                                                small
                                                                v-on="{...tooltip, ...menu}"
                                                            >
                                                                <v-icon v-on small color="grey darken-4">ti ti-export</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.export') }}</span>
                                                    </v-tooltip>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item @click="exportTable('pdf','leads')">
                                                        <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="exportTable('excel','leads')">
                                                        <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </template>
                                </v-card>
                                <div class="life-cycle-column" :style="cssVars">
                                    <v-text-field
                                        v-model="searchText.leads"
                                        clearable
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        class="mt-0"
                                        hide-details="auto"
                                        @keydown.enter.prevent="searchCustomer('leads')"
                                        @click:clear="searchText.leads = null; searchCustomer('leads')"
                                    ></v-text-field>
                                    <v-virtual-scroll item-height="50" :items="leads.filteredItems" :height="scrollerHeight" class="my-1">
                                        <template v-slot:default="{ item }">
                                            <LifeCycleItem :item="item"></LifeCycleItem>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-col>
                            <v-col class="pl-1 cols-20-pct">
                                <v-card class="blue-card" tile outlined>
                                    <template v-if="sleepingBuyers.loading">
                                        <v-progress-circular
                                            color="white"
                                            indeterminate
                                            size="30"
                                            width="4"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="conversion-widget text--black">
                                            {{ $t('message.sleepingBuyers') + ' - ' + sleepingBuyers.total.toString() }}
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on: menu }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                class="float-right"
                                                                icon
                                                                small
                                                                v-on="{...tooltip, ...menu}"
                                                            >
                                                                <v-icon v-on small color="grey darken-4">ti ti-export</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.export') }}</span>
                                                    </v-tooltip>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item @click="exportTable('pdf','sleepingBuyers')">
                                                        <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="exportTable('excel','sleepingBuyers')">
                                                        <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </template>
                                </v-card>
                                <div class="life-cycle-column" :style="cssVars">
                                    <v-text-field
                                        v-model="searchText.sleepingBuyers"
                                        clearable
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        class="mt-0"
                                        hide-details="auto"
                                        @keydown.enter.prevent="searchCustomer('sleepingBuyers')"
                                        @click:clear="searchText.sleepingBuyers = null; searchCustomer('sleepingBuyers')"
                                    ></v-text-field>
                                    <v-virtual-scroll item-height="50" :items="sleepingBuyers.filteredItems" :height="scrollerHeight" class="my-1">
                                        <template v-slot:default="{ item }">
                                            <LifeCycleItem :item="item"></LifeCycleItem>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-col>
                            <v-col class="pl-1 cols-20-pct">
                                <v-card class="amber-card" tile outlined>
                                    <template v-if="prospects.loading">
                                        <v-progress-circular
                                            color="white"
                                            indeterminate
                                            size="30"
                                            width="4"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="conversion-widget text--black">
                                            {{ $t('message.prospects') + ' - ' + prospects.total.toString() }}
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on: menu }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                class="float-right"
                                                                icon
                                                                small
                                                                v-on="{...tooltip, ...menu}"
                                                            >
                                                                <v-icon v-on small color="grey darken-4">ti ti-export</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.export') }}</span>
                                                    </v-tooltip>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item @click="exportTable('pdf','prospects')">
                                                        <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="exportTable('excel','prospects')">
                                                        <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </template>
                                </v-card>
                                <div class="life-cycle-column" :style="cssVars">
                                    <v-text-field
                                        v-model="searchText.prospects"
                                        clearable
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        class="mt-0"
                                        hide-details="auto"
                                        @keydown.enter.prevent="searchCustomer('prospects')"
                                        @click:clear="searchText.prospects = null; searchCustomer('prospects')"
                                    ></v-text-field>
                                    <v-virtual-scroll item-height="50" :items="prospects.filteredItems" :height="scrollerHeight" class="my-1">
                                        <template v-slot:default="{ item }">
                                            <LifeCycleItem :item="item"></LifeCycleItem>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-col>
                            <v-col class="pl-1 cols-20-pct">
                                <v-card class="green-card" tile outlined>
                                    <template v-if="opportunities.loading">
                                        <v-progress-circular
                                            color="white"
                                            indeterminate
                                            size="30"
                                            width="4"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="conversion-widget text--black">{{ $t('message.opportunities') + ' - ' + opportunities.total.toString() }}</div>
                                    </template>
                                </v-card>
                                <div class="life-cycle-column" :style="cssVars">
                                    <v-text-field
                                        v-model="searchText.opportunities"
                                        clearable
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        class="mt-0"
                                        hide-details="auto"
                                        @keydown.enter.prevent="searchCustomer('opportunities')"
                                        @click:clear="searchText.opportunities = null; searchCustomer('opportunities')"
                                    ></v-text-field>
                                    <v-virtual-scroll item-height="50" :items="opportunities.filteredItems" :height="scrollerHeight" class="my-1">
                                        <template v-slot:default="{ item }">
                                            <LifeCycleItem :item="item"></LifeCycleItem>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-col>
                            <v-col class="pl-1 cols-20-pct">
                                <v-card class="dark-green-card" tile outlined>
                                    <template v-if="newBuyers.loading">
                                        <v-progress-circular
                                            color="white"
                                            indeterminate
                                            size="30"
                                            width="4"
                                        />
                                    </template>
                                    <template v-else>
                                        <div class="conversion-widget">{{ $t('message.newBuyers') + '* - ' + newBuyers.total.toString() }}</div>
                                    </template>
                                </v-card>
                                <div class="life-cycle-column" :style="cssVars">
                                    <v-text-field
                                        v-model="searchText.newBuyers"
                                        clearable
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        class="mt-0"
                                        hide-details="auto"
                                        @keydown.enter.prevent="searchCustomer('newBuyers')"
                                        @click:clear="searchText.newBuyers = null; searchCustomer('newBuyers')"
                                    ></v-text-field>
                                    <v-virtual-scroll item-height="50" :items="newBuyers.filteredItems" :height="scrollerHeight" class="my-1">
                                        <template v-slot:default="{ item }">
                                            <LifeCycleItem :item="item"></LifeCycleItem>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {api} from "Api";
import LifeCycleItem from "Components/Appic/LifeCycleItem.vue";
import {mapGetters} from 'vuex';

export default {
    name: "CustomerLifeCycle",
    components: { LifeCycleItem },
    data() {
        return {
            columnHeight: 500,
            scrollerHeight: 450,
            showNoPotentials: false,
            filterItems: {
                country: [],
                owner: [],
                status: [
                    {value: 1, text: this.$t('message.all')},
                    {value: 2, text: this.$t('message.hubSpotOnly')},
                    {value: 3, text: this.$t('message.appicOnly')}
                ]
            },
            filterValues: {
                country: null,
                owner: null,
                status: 1,
            },
            currentFilterValues: {
                country: null,
                owner: null
            },
            leads: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            },
            loader: false,
            loading: {
                filterItems: {
                    owner: false,
                },
                clear: false,
            },
            newBuyers: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            },
            noPotentials: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            },
            opportunities: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            },
            prospects: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            },
            searchText: {
                leads: null,
                prospects: null,
                opportunities: null,
                newBuyers: null,
                noPotentials: null,
                sleepingBuyers: null
            },
            searchTerm: {
                leads: null,
                prospects: null,
                opportunities: null,
                newBuyers: null,
                noPotentials: null,
                sleepingBuyers: null
            },
            sleepingBuyers: {
                filteredItems: [],
                items: [],
                loading: false,
                total: 0
            }
        }
    },
    computed: {
        ...mapGetters('user',{
            User: 'User'
        }),
        cssVars() {
            return {
                '--column-height': this.columnHeight.toString() + 'px'
            }
        }
    },
    methods: {
        clearFilters() {
            this.filterValues.owner = null;
            this.filterCustomers();
        },
        async exportTable(reportType, stage) {
            if (this[stage]['items'].length > 0) {
                let date = new Date()
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                let fullDate = year + "-" + month + "-" + day

                let reportFileName = 'List_' + fullDate
                let reportFileExtension = '.pdf'
                let reportFileType = 'application/pdf'
                if(reportType === 'excel'){
                    reportFileExtension = '.xlsx'
                    reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                try {
                    const headers = [
                        {
                            text: this.$t('message.stage'),
                        },
                        {
                            text: this.$t('message.country'),
                        },
                        {
                            text: this.$t('message.buyer'),
                        },
                        {
                            text: this.$t('message.salesColleague'),
                        }
                    ];
                    this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                        classes: ['text--white'],
                        color: 'info',
                        timeout: 0,
                        icon: 'check',
                        iconColor: 'white',
                        x: 'center',
                        y: 'top'
                    })

                    api
                        .post('/customers/lifecycle/export', {
                            data: {
                                customers: this[stage]['items'],
                            },
                            fields: headers,
                            type: reportType
                        }, {
                            responseType: 'blob'
                        })
                        .then((file) => {
                            const url = window.URL.createObjectURL(new Blob([file.data], {type: reportFileType}));
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank'
                            link.setAttribute('download', reportFileName + reportFileExtension);
                            link.click();
                            link.remove();
                            window.URL.revokeObjectURL(url);
                            //close notification
                            let cmp = this.$toast.getCmp()
                            cmp.close()
                        })
                        .catch((error) => {
                            let cmp = this.$toast.getCmp()
                            cmp.close()
                            this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                                timeout: 7000,
                                x: 'center',
                                y: 'top'
                            })
                        })
                } catch (error) {
                    let cmp = this.$toast.getCmp()
                    cmp.close()
                    this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                        timeout: 7000,
                        x: 'center',
                        y: 'top'
                    })
                }
            } else {
                alert(this.$t('message.noDataToExport'))
            }
        },
        filterCustomers() {
            if(this.currentFilterValues['owner'] !== this.filterValues.owner) {
                this.currentFilterValues['owner'] = this.filterValues.owner;
                this.searchText.leads = null;
                this.searchText.prospects = null;
                this.searchText.opportunities = null;
                this.searchText.noPotentials = null;
                this.searchText.newBuyers = null;
                this.loadFilterItems('country')
                    .then(() => {
                        this.loadCustomers();
                    });
            } else {
                this.loadCustomers();
            }
        },
        handleResize() {
            this.columnHeight = window.innerHeight - (208);
            this.scrollerHeight = window.innerHeight - (243);
        },
        loadCustomers() {
            let conditions = [];
            if(this.filterValues.owner != null){
                conditions.push({field: 'owner', value: this.filterValues.owner})
            }
            if(this.filterValues.country != null){
                conditions.push({field: 'country', value: this.filterValues.country})
            }
            if(this.filterValues.status != null){
                conditions.push({field: 'status', value: this.filterValues.status})
            }
            this.leads.items = [];
            this.prospects.items = [];
            this.opportunities.items = [];
            this.newBuyers.items = [];
            this.noPotentials.items = [];
            this.sleepingBuyers.items = [];

            this.leads.loading = true;
            this.prospects.loading = true;
            this.opportunities.loading = true;
            this.newBuyers.loading = true;
            this.noPotentials.loading = true;
            this.sleepingBuyers.loading = true;

            api
                .get('/customers/lifecycle', {
                    params: {
                        conditions: conditions,
                    }
                })
                .then((response) => {
                    if(response.data.status == 'success') {
                        const customers = response.data.data;

                        this.leads.total = 0;
                        this.prospects.total = 0;
                        this.opportunities.total = 0;
                        this.newBuyers.total = 0;
                        this.noPotentials.total = 0;
                        this.sleepingBuyers.total = 0;

                        this.leads.items = customers.leads;
                        this.prospects.items = customers.prospects;
                        this.opportunities.items = customers.opportunities;
                        this.newBuyers.items = customers.newBuyers;
                        this.noPotentials.items = customers.noPotentials;
                        this.sleepingBuyers.items = customers.sleepingBuyers;

                        this.leads.filteredItems = customers.leads;
                        this.prospects.filteredItems = customers.prospects;
                        this.opportunities.filteredItems = customers.opportunities;
                        this.newBuyers.filteredItems = customers.newBuyers;
                        this.noPotentials.filteredItems = customers.noPotentials;
                        this.sleepingBuyers.filteredItems = customers.sleepingBuyers;

                        if(customers.leads.length > 0) this.leads.total = customers.leads.length;
                        if(customers.prospects.length > 0) this.prospects.total = customers.prospects.length;
                        if(customers.opportunities.length > 0) this.opportunities.total = customers.opportunities.length;
                        if(customers.newBuyers.length > 0) this.newBuyers.total = customers.newBuyers.length;
                        if(customers.noPotentials.length > 0) this.noPotentials.total = customers.noPotentials.length;
                        if(customers.sleepingBuyers.length > 0) this.sleepingBuyers.total = customers.sleepingBuyers.length;

                    } else {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded') ,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }

                    this.leads.loading = false;
                    this.prospects.loading = false;
                    this.opportunities.loading = false;
                    this.newBuyers.loading = false;
                    this.noPotentials.loading = false;
                    this.sleepingBuyers.loading = false;

                })
                .catch((error) => {
                    this.$toast.error(this.$t('message.error') + ': ' + error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )

                    this.leads.loading = false;
                    this.prospects.loading = false;
                    this.opportunities.loading = false;
                    this.newBuyers.loading = false;
                    this.noPotentials.loading = false;
                    this.sleepingBuyers.loading = false;

                })
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if (filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true;
                    let conditions = null;
                    if (filter === 'country') {
                        conditions = [
                            {
                                field: 'owner',
                                value: this.filterValues.owner
                            }
                        ];
                    }
                    api
                        .get('/customers/lifecycle/filter-options/' + filter, {
                            params: {
                                conditions: conditions
                            }
                        })
                        .then(response => {
                            if (response.data.status == 'success') {
                                this.filterItems[filter] = response.data.data;
                                this.loading.filterItems[filter] = false;

                                if (filter == 'owner') {
                                    this.filterValues[filter] = this.filterItems[filter][0]['Owner']['id'];
                                    this.currentFilterValues[filter] = this.filterValues[filter];
                                }
                                if (filter == 'country') {
                                    this.filterValues[filter] = this.filterItems[filter][0]['Country']['id'];
                                    this.currentFilterValues[filter] = this.filterValues[filter];
                                }
                                resolve('done')
                            } else {
                                this.loading.filterItems[filter] = false;
                                reject(response.data.status);
                            }
                        })
                        .catch(error => {
                            this.loading.filterItems[filter] = false;
                            reject(error);
                        })
                } else {
                    reject('Filter empty')
                }
            });
        },
        searchCustomer(stage) {
            this.$nextTick(function(){
                this.searchTerm[stage] = this.searchText[stage]
                if(this.searchTerm[stage] != null){
                    this[stage]['filteredItems'] = this[stage]['items'].filter((item) => {
                        return (
                            this.searchTerm[stage] != null
                                ? item.Customer.buyer_name.toLocaleLowerCase().includes(this.searchTerm[stage].toLocaleLowerCase())
                                : item.Customer.buyer_country.toLocaleLowerCase().includes(this.searchTerm[stage].toLocaleLowerCase())
                        );
                    })
                } else {
                    this[stage]['filteredItems'] = this[stage]['items']
                }
            })
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted(){
        this.handleResize();
        this.$title = this.$t('message.titles.buyerLifeCycle');
        this.loadFilterItems('owner')
            .then(() => {
                const owner = this.filterItems.owner.find(o => o.Owner.id === this.User.email)
                if(owner) {
                    this.filterValues.owner = this.User.email;
                }
                this.loadFilterItems('country')
                    .then(() => {
                        this.filterCustomers()
                    });
            });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>


<style scoped>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.v-card.blue-card {
    background-color: #5D92F4 !important;
}
.v-card.dark-green-card {
    background-color: #3C6932 !important;
}
.v-card.green-card {
    background-color: #82A05A !important;
}
.v-card.amber-card {
    background-color: #F08700 !important;
}
.v-card.grey-card {
    background-color: #878787 !important;
}
.v-card.red-card {
    background-color: red !important;
}
.v-card.white-card {
    border-color: dimgrey !important;
}
.v-card div {
    font-weight: bold;
    font-size: 1.25em;
    padding: 0px 0px;
    text-align: center;
    color: white;
}
.v-card div.text--black {
    color: black !important;
}
.life-cycle-column {
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: dimgrey;
    border-style: solid;
    height: var(--column-height);
    //background-color: #00D0BD;
}
.col-20-pct {
    width: 20% !important;
}
</style>