<template>
    <div>
        <v-list-item :key="item.uniqid" dense>
            <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-bold">{{ item.Customer.buyer_name }}</v-list-item-title>
                <v-list-item-subtitle>
                    <div class="d-flex flex-row align-center">
                        <v-icon class="mdi mdi-triangle mr-2" color="success" small v-if="item.Customer.in_appic == 'Y'"></v-icon>
                        <span class="font-italic text-gray" v-if="item.Customer.buyer_country != null">{{ item.Customer.buyer_country }}</span>
                    </div>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-icon color="success" v-if="item.Customer.new_current_stage == true">fiber_manual_record</v-icon>
            <v-list-item-action class="my-0 ml-0" v-if="item.Customer.hs_id != null">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn icon @click="openCompanyInHubSpot(item)" v-on="{...tooltip}">
                            <v-icon class="mdi mdi-hubspot" style="color: #FF7A59 !important;"></v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('message.openHubSpotCompany') }}</span>
                </v-tooltip>
            </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
    </div>
</template>

<script>
    export default {
        name: "LifeCycleItem",
        props: ['item'],
        data(){
            return {
            }
        },
        methods: {
            openCompanyInHubSpot(item) {
                window.open(
                    process.env.VUE_APP_HUBSPOT
                    + '/contacts/'
                    + item.Customer.main_contact_id
                    + '/company/'
                    + item.Customer.hs_id
                    , "_blank")
            },
        },
        mounted(){

        }
    }
</script>

<style scoped>

</style>